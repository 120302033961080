export default {
  title: 'Data Explorer',
  select: 'Select an object',
  moreInfo: 'More info',
  explore: 'Explore your data in the left panel for details of an object',
  workflows: {
    createDisabled: 'Region settings for the table are not accessible within workflows.',
    createRasterDisabled: 'Region settings for the raster are not accessible within workflows.'
  },
  recentDatasets: {
    title: 'Recently used data'
  },
  actions: {
    copyId: 'Copy ID'
  },
  error: {
    reconnect: 'We lost access to this connection. Please repair it and refresh.'
  },
  accessInModal: {
    title: 'Access in {provider}',
    titleGeneric: 'Access in',
    description: {
      dataset:
        'Your <strong>Data Observatory subscription</strong> is composed of a data and a geography tables. Find below where you can find these tables in {provider} and a sample query to join them.',
      geography: 'Find below the location of your Data Observatory subscription in {provider} and a sample query.'
    },
    connections: 'Select a {provider} connection',
    connectionsGeneric: 'Select a connection',
    dataset: 'Data table',
    geography: 'Geography table',
    sample: 'Sample query',
    request: {
      done: {
        title: 'Access requested',
        description:
          'You will be able to create your map using this connection as soon as your request has been fulfilled.'
      },
      title: 'Please request access first',
      description:
        'Please request to access your Data Observatory <strong>subscription</strong> from your {provider} account and we will get back to you shortly.',
      description2: 'Access details will be displayed on this page as soon as your request has been fulfilled.'
    }
  },
  tabs: {
    explore: 'Explore',
    properties: 'Properties'
  },
  noGeomDataWarningOld:
    "<strong>This table doesn't contain any geom data.</strong> You must geocode the dataset to create maps.",
  noGeomDataWarning:
    'The spatial column and type definition for this table cannot be recognized. Consider geocoding the dataset to create a map.',
  noGeomDataWarningLink: 'More info',
  optimizeTable: {
    notOptimized: {
      button: 'Optimize this table',
      buttonPrepare: 'Prepare this table'
    },
    alreadyOptimized: {
      text: 'This table was recently optimized for better geospatial performance.',
      button: 'Open optimized table',
      textPrepare: 'This table was recently prepared for visualization.',
      buttonPrepare: 'Open prepared table'
    },
    modal: {
      optimize: {
        title: 'Optimize your table',
        hint: 'Learn more about optimization in our {documentationLink}',
        documentationLink: 'Documentation',
        steps: {
          destination: {
            title: 'Destination',
            message:
              'Select the output destination where your optimized table will be stored. Please, make sure you have the necessary write permissions.',
            submit: 'Create here',
            label: 'Optimized table name'
          },
          confirmation: {
            title: 'Confirmation',
            message: 'You are going to create the following optimized table',
            submit: 'Create'
          }
        }
      },
      prepare: {
        title: 'Prepare your table',
        hint: 'Learn more about prepare a table in our {documentationLink}',
        documentationLink: 'Documentation',
        steps: {
          destination: {
            title: 'Destination',
            message:
              'Select the output destination where your prepared table will be stored. Please, make sure you have the necessary write permissions.',
            submit: 'Create here',
            label: 'Prepared table name'
          },
          confirmation: {
            title: 'Confirmation',
            message: 'You are going to create the following prepared table',
            submit: 'Create'
          }
        }
      }
    }
  },
  addNewConnection: 'Add new connection',
  addNewSubscriptions: 'Add new subscriptions',
  folder: {
    buttons: {
      importData: 'Import data',
      importDataNotAvailable:
        "Importing on {provider} isn't available yet. Contact support@carto.com if you'd like to submit a feature request.",
      importFileTypeNotSupported: 'Cloud Optimized GeoTiff imports are not supported for this data warehouse.',
      refresh: 'Refresh',
      refreshType: 'Refresh {type}',
      gridView: 'Grid view',
      tableView: 'Table view',
      showMore: 'Show more...'
    },
    childrenLabels: {
      project: 'Projects',
      dataset: 'Datasets',
      schema: 'Schemas',
      database: 'Databases',
      table: 'Tables & tilesets'
    },
    empty: {
      project: 'No projects in this {folderType}',
      dataset: 'No datasets in this {folderType}',
      schema: 'No schemas in this {folderType}',
      database: 'No databases in this {folderType}',
      table: 'No tables & tilesets in this {folderType}',
      import: 'Do you want to import data?'
    },
    search: {
      results: {
        generic: 'Viewing {moreResults, select, true {the first } other {}}{nbResults} results matching {searchTerm}',
        loading: 'searching {searchTerm}'
      },
      placeholder: {
        search: 'Search',
        generic: 'Search in {folder}',
        project: 'Search projects in {folder}',
        dataset: 'Search datasets in {folder}',
        schema: 'Search schemas in {folder}',
        database: 'Search databases in {folder}',
        table: 'Search tables and tilesets in {folder}'
      },
      noResult: {
        generic: 'No result for your search',
        project: 'No project for your search',
        dataset: 'No dataset for your search',
        schema: 'No schema for your search',
        database: 'No database for your search',
        table: 'No table & tileset for your search',
        selectProject: 'Searching for a dataset? Select a project first.'
      },
      childrenLabel: 'Viewing {moreResults, select, true {the first } other {}}',
      matchingKeyword: ' matching "{keyword}"'
    },
    currentChildrenLabels: 'Viewing {count} out of ',
    many: 'many',
    totalChildrenLabels: {
      project: `{count} {count, plural,
          one {project}
          other {projects}
      }`,
      dataset: `{count} {count, plural,
          one {dataset}
          other {datasets}
      }`,
      database: `{count} {count, plural,
        one {result}
        other {results}
      }`,
      schema: `{count} {count, plural,
          one {result}
          other {results}
      }`,
      table: `{count} {count, plural,
        one {result}
        other {results}
      }`
    },
    tableColTitles: {
      name: 'Name',
      type: 'Type',
      provider: 'Provider'
    }
  },
  tree: {
    explorer: 'Connections',
    observatory: 'Data Observatory'
  },
  observatory: {
    folders: {
      samples: 'Samples',
      subscriptions: 'Subscriptions'
    }
  },
  metas: {
    category: 'Category',
    kind: 'Type',
    provider: 'Provider',
    lastModified: 'Last updated',
    size: 'Size',
    rows: 'Number of rows',
    geomType: 'Geometry type',
    expiresAtDate: 'Expires {date}',
    numberOfSeats: 'Number of seats',
    tilesetType: 'Tileset type'
  },
  subscription: {
    sample: 'sample',
    subscription: 'subscription',
    deleteSample: 'Delete sample',
    delete: 'Unsubscribe',
    deleteDialog: {
      titleSample: 'Delete sample',
      title: 'Unsubscribe',
      contentSample: 'Are you sure you want to delete this sample?',
      content: 'Are you sure you want to unsubscribe from this dataset?',
      warning: {
        title: 'Warning:',
        1: 'This action will remove this {type} for all users of your organization.',
        2: 'Maps and workflows that make use of this data {type} may be affected.'
      },
      errorSample:
        'Error: This sample cannot be deleted as it contains geography data required by other samples. Please delete the dependant samples first.',
      error:
        'Error: You cannot unsubscribe from this dataset as it contains geography data required by other subscriptions. Please unsubscribe from the dependant subscriptions first.'
    },
    export: 'Export data',
    exportDialog: {
      title: 'Export data',
      contentWithFilter: {
        title: 'Data releases',
        text: 'Select the release dates you want to export. Please modify the SQL query of the export job in case you want to apply additional filters to the subscription before exporting. Check our <a>product documentation</a> to learn more about advanced options to filter your subscription.'
      },
      contentWithNoFilter: {
        title: 'Apply filters before exporting',
        text: 'Please modify the SQL query of the export job in case you want to apply additional filters to the subscription before exporting. Check our <a>product documentation</a> to learn more about advanced options to filter your subscription.'
      },
      form: {
        select: {
          label: "Available 'do_date' values"
        }
      },
      cantTransform:
        "Default query for exporting data can't be applied for this subscription. This subscription has complex fields that require the user to customize the SQL expression. Learn more in our <a>Documentation</a>",
      submit: 'Export data',
      done: 'Done',
      minimize: 'Minimize',
      retry: 'Retry',
      sqlEditor: 'SQL Editor',
      newExport: 'New export',
      sqlEditorWarning: 'If you disable the SQL editor, you will clear the query and lose all changes made.',
      download: {
        title: 'Download',
        parts: `{count} {count, plural,
          one {part}
          other {parts}
        }`,
        availableUntil: 'Available until: {date} at {hour}'
      }
    }
  },
  tables: {
    size: 'Table Size',
    rows: '{rows} rows',
    geoType: 'Type of Geography',
    map: 'Map',
    preview: 'Data preview',
    mapPreviewNotAvailable: 'Map preview not available',
    unsupportedGeometryType: 'Geometry not supported for this provider.',
    invalidGeomField: "We couldn't find a geometry column in this table.",
    prepareTable: 'Prepare your table to enable visualization',
    createTileset: 'Create a tileset',
    createMap: 'Create map',
    and: 'and',
    tooLarge: {
      title: 'Table too large',
      info: 'The map will be created with this dataset added as SQL query with a filter. You can also create a tileset to visualize the whole table.',
      infoNoTileset: 'The map will be created with this dataset added as SQL query with a filter.'
    },
    fqn: {
      where: 'Where',
      copy: 'Copy qualified name',
      copied: 'Copied!'
    },
    geometry: 'Geometry',
    delete: {
      table: 'Delete table',
      tileset: 'Delete tileset',
      raster: 'Delete raster'
    },
    enrich: 'Enrich table'
  },
  deleteResourceDialog: {
    title: 'Delete {type}',
    info: 'You are about to delete {tableName} from your {connectionName} connection.',
    info2: 'This action cannot be undone:',
    bullets: {
      1: 'Builder data sources and layers using this {type} will be broken.',
      2: 'Applications and API calls that rely on this {type} will stop working.'
    },
    delete: 'Yes, delete',
    errorMessage: "We couldn't delete the {type}, more info:"
  },
  createConnection: 'Connect your data warehouse to list your data',
  cantCreateConnection: `There aren't any data warehouses connected to your organization. This can be done by users with Editor or Admin privileges`,
  or: 'Or',
  browseCatalog: 'Browse and subscribe to 9,000+ spatial data from best data providers',
  browseCatalogLink: `Browse the Spatial Data Catalog`,
  kinds: {
    table: 'Table',
    tileset: 'Tileset',
    raster: 'Raster'
  },
  form: {
    settings: {
      tilesetType: {
        label: 'Select the points tileset type',
        helper:
          'In the aggregated tileset points will be converted into grid cells, with each one of them aggregating the data from all points falling inside it.',
        options: {
          simple: 'Simple',
          aggregation: 'Aggregated'
        }
      },
      name: {
        label: 'Output tileset',
        helper: 'Type the name of your new tileset.'
      },
      geomField: {
        label: 'Geom column',
        helper: 'Select the geometry column.'
      },
      outputFormat: {
        label: 'Output format',
        helper: 'Select the output format',
        options: {
          geometries: 'Geometries',
          h3: 'H3',
          quadbin: 'Quadbin'
        }
      },
      aggregationPlacement: {
        label: 'Aggregation placement',
        options: {
          'cell-centroid': 'Cell Centroid',
          cell: 'Cell',
          'features-any': 'Features Any',
          'features-centroid': 'Features Centroid'
        }
      },
      zoomLevel: {
        label: 'Zoom level',
        helper: 'Set the min and max zoom. Recommended values: 0-12 for points, 2-12 for others.'
      },
      description: {
        label: 'Description',
        placeholder: 'Enter description'
      },
      resolutionLevel: {
        label: 'Resolution level',
        helper: `Set the min and max resolution.`
      },
      spatialIndexField: {
        label: 'Spatial index',
        helper: ' ',
        h3Type: 'H3',
        quadbinType: 'Quadbin'
      }
    },
    attributes: {
      description: {
        bigquery:
          'Select the columns to include in the tileset (max. 60). For best results we recommend up to 3 attributes.',
        other: 'Select the columns to include in the tileset. For best results we recommend up to 3 attributes.'
      },
      search: 'Search column',
      selected: '{size} selected',
      aggregation: {
        tooltip: 'Aggregation method',
        label: 'Choose an aggregation method',
        error: 'Please choose method'
      }
    },
    confirmation: {
      description: 'You are going to create the following tileset',
      where: 'Where:',
      selected: 'Attributes selected',
      tileset: 'Tileset',
      table: 'Table'
    }
  },
  providers: {
    bigquery: 'BigQuery',
    snowflake: 'Snowflake',
    redshift: 'Redshift',
    postgres: 'PostgreSQL',
    databricks: 'Databricks',
    databricksRest: 'Databricks',
    cartoDw: 'CARTO Data Warehouse'
  },
  map: {
    loadingLayer: 'Loading map',
    loadingMsg: 'Please wait, this may take several seconds',
    createModal: {
      title: 'Create map',
      subtitle: 'Select the connected Data Warehouse where your map will be created.'
    }
  },
  geocode: {
    processing: {
      title: 'Processing',
      message:
        'Geocoding may take several minutes. To continue your work, minimize the process while it completes in the background.',
      minimize: 'Minimize process'
    },
    detail: {
      title: 'Geocode table',
      steps: {
        1: 'Settings',
        2: 'Confirmation',
        3: 'Confirmation'
      }
    },
    form: {
      output: {
        label: 'Output table',
        helper: 'Type the qualified name of your new table. Please, make sure you have the necessary write permission.'
      },
      type: 'Geocode by',
      types: {
        address: 'Address',
        latlng: 'Latitude / longitude'
      },
      latLng: {
        description: 'A geometry column will be added to your existing table.',
        latitude: {
          label: 'Latitude',
          helper: 'Select the column containing the latitude values.'
        },
        longitude: {
          label: 'Longitude',
          helper: 'Select the column containing the longitude values.'
        },
        geom: {
          label: 'Geom column name',
          placeholder: 'geom',
          helper: 'Choose a name for the geometry column where the geocoding results will be stored.'
        }
      },
      address: {
        description:
          'Two new columns will be added to your table: a geometry column and a metadata column with additional information regarding the geocoding results.',
        label: 'Address column',
        helper: 'Select the column containing the addresses to be geocoded.',
        bigquery: {
          unavailable:
            'This feature is coming soon. To learn more, please contact us at <strong>feedback@carto.com</strong>.'
        },
        spatialExtension: {
          unavailable:
            'You need to have the <link>Analytics Toolbox</link> installed in your database in order to use this feature. Please contact us at <strong>support@carto.com</strong> and we will guide you through the installation process.'
        }
      },
      country: {
        label: 'Country',
        helper: 'Select the country of the addresses to be geocoded.',
        tabs: {
          table: 'From table',
          list: 'Country list'
        },
        none: {
          column: 'Select column',
          country: 'Select country'
        }
      },
      maxNumberOfResults: {
        label: 'Maximum number of results',
        helper:
          'If you select more than one, the output table may include an array of results instead of a single record.'
      },
      confirmation: {
        description: 'You are going to geocode the following table'
      },
      submit: 'Geocode'
    }
  },
  tilesets: {
    'no-connection': {
      button: 'Create your first connection'
    },
    tilesetTypes: {
      aggregated: 'aggregated',
      simple: 'simple'
    },
    detail: {
      title: 'Tileset details',
      seatsWarning: {
        message:
          'Please ensure a maximum of <strong>MAX_SEATS of the TOTAL_USERS users</strong> of the account are making use of this dataset or request more seats.'
      },
      tabs: {
        map: 'Map',
        details: 'Details',
        metadata: 'Metadata'
      },
      titleButtons: {
        create: 'Create',
        createWorkflow: 'Create workflow',
        createMap: 'Create map',
        createMapTooltip: 'Create a map in Builder',
        canNotcreateMapTooltip: "This table doesn't contain any geometry data or is empty",
        developers: 'Developers',
        geocode: 'Geocode table'
      },
      params: {
        seats: '{num} seats',
        minzoom: 'Zoom min',
        maxzoom: 'Zoom max',
        center: 'Center',
        bounds: 'Bounds',
        count: 'Count',
        geometry_type: 'Type of Geometry'
      },
      chart: {
        title: 'Tile size by zoom level (KB)',
        selector: {
          average: 'Average',
          max: 'Maximum'
        }
      },
      tileset: {
        title: 'Create tileset',
        onboardingText: `A vector tileset is a representation of the data optimized for map visualizations, which allows to process and visualize massive spatial datasets with CARTO natively in your data warehouse.`,
        onboardingText2: `Tilesets are generated in SQL using the procedures available in the tiler module of the <a>Analytics Toolbox</a> for the different data warehouse platforms. The type of tileset will depend on the nature of the geometries in your source table.`,
        steps: {
          destination: 'Destination',
          settings: 'Settings',
          attributes: 'Attributes',
          confirmation: 'Confirmation',
          connection: 'Connection'
        },
        location: {
          subtitle:
            'Select the output destination where your tileset will be stored. Note that the tileset needs to be stored in a project on the same cloud region as the input table. Please, make sure you have the necessary write permission.'
        },
        advance: 'Advanced',
        editSql: 'Edit SQL parameter',
        output: 'Output table',
        warning: 'If you disable the SQL editor, you will clear the query and lose all changes made.',
        attributes: {
          aggregation:
            'Some columns are disabled because in these cases you should specify the aggregation formula into the SQL editor.',
          bigquery:
            'Numeric, string, boolean, date and timestamp types are supported. Date and timestamps will be encoded as strings.',
          other: 'Numeric and string types are supported. All other types will be encoded as strings.'
        },
        learn: 'Learn more about tilesets in our <a>Documentation</a>.',
        help: {
          bigquery:
            'Please refer to the <a>SQL reference</a> to learn valid values and types for each of these option parameters. All parameters set as NULL will take its default value.',
          other:
            'Please refer to the <a>SQL reference</a> for all available options and supported values. All uniformed options will take its default value.'
        },
        filterColumnsWarning: {
          description: 'It is strongly recommended to use the SQL editor to filter this dataset. <a>Collapse info.</a>',
          expand: 'Expand info',
          collapse: 'Collapse info',
          list: {
            title: 'Please create a tileset of the filtered dataset',
            description:
              'Due to the particular data structure of this Data Observatory dataset, it is strongly recommended to use the provided <strong>SQL Editor</strong> to filter the data by the following columns:'
          }
        }
      },
      metadata: {
        title: 'Metadata'
      }
    }
  },
  rasters: {
    'no-connection': {
      button: 'Create your first connection'
    },
    detail: {
      tabs: {
        map: 'Map',
        details: 'Details',
        metadata: 'Metadata'
      },
      params: {
        seats: '{num} seats',
        blocks: 'Blocks',
        center: 'Center',
        blocksize: 'Block size',
        pixels: 'Pixels',
        bounds: 'Bounds',
        bands: 'Bands'
      },
      metadata: {
        title: 'Metadata'
      }
    }
  },
  dataSubscription: {
    title: 'Data Observatory',
    error: "We couldn't fetch your samples.",
    errorAction: 'Click here to get more details',
    errorInfo: 'Error details:'
  },
  import: {
    title: 'Import data',
    steps: {
      1: 'Select file',
      2: 'Destination',
      3: 'Schema Preview',
      4: 'Confirmation'
    },
    step1Description: 'You can import a local file from your computer, or a file from a URL.',
    step2Description: 'Select the destination where your table will be stored, and customize the name if needed.',
    saveHere: 'Save here',
    importAndReplace: 'Import and replace',
    uploadPlaceHolder: 'E.g.: file.geojson, file.csv, shapefile.zip',
    supportedFileFormatsDescription:
      'Shapefiles, GeoPackage, CSV, KML, KMZ, TAB, Cloud Optimized GeoTIFF, GeoJSON and (Geo)Parquet files are supported',
    supportedFileFormats: 'supported file formats.',
    tableName: 'Imported {entity} name',
    tableNameDescription: 'Type a name for the table created by importing this dataset',
    invalidLocation: 'Unable to import into this location',
    tableNamePlaceholder: 'E.g.: ne_10m_populated_places_simple',
    confirmationDescription: 'Please, review the details before importing.',
    imports: 'Imports',
    importingDescription:
      "This process might take a bit to complete. Click on 'Minimize' to continue working while it runs in the background.",
    chooseMethod: 'Choose method',
    autoGuessing: 'Let CARTO automatically define the schema',
    autoGuessingShort: 'Let CARTO define the schema',
    autoGuessDescription:
      'By default, CARTO will try to guess the data types of each column in your file. If you want to manually specify a schema, turn this option off.',
    localFile: 'File',
    enterURL: 'URL',
    notValidUrl: 'Unfortunately, the provided URL is not valid.',
    notValidFileSize: 'The local file cannot be larger than',
    datasetOtherError: 'Please, choose a new name or destination and contact us if the issue persists.',
    datasetOtherErrorTitle: "It seems we can't use this destination or table name",
    datasetAlreadyExistsOverwrite:
      'This table already exists in the destination folder. If you continue it’ll be overwritten.',
    datasetAlreadyExistsOverwriteTitle: 'The {entity} will be overwritten',
    datasetAlreadyExistsError:
      'A table with this name already exists in this destination. Please, choose a new name or destination and try again.',
    datasetAlreadyExistsErrorTitle: '{entity} name already exists',
    locationNotFoundTitle: 'Table or schema does not exist',
    locationNotFoundError:
      'The table or schema you specified does not exist. Please, select a new destination and try again.',
    invalidName: "Invalid name. It can contain only alphanumeric characters (a-z, A-Z, 0-9) and separators ('-', '_').",
    invalidNameLength: 'Table name must not exceed {maxLength} characters.',
    accessDenied:
      "This connection doesn't have write permissions in this schema/database. Please, select a new destination and try again.",
    accessDeniedTitle: "You don't have write permissions",
    connectionSelectorPrompt: 'Select an available connection and then a folder location',
    previewAlert:
      'This is a preview. Final data types might be different after a deeper column analysis when importing',
    columnName: 'Column name',
    typePreview: 'Type preview',
    automatic: 'automatic',
    schemaLeaveConfirm: {
      title: 'Are you sure you want to leave?',
      description: 'If you leave or go back now, you will lose your current custom schema'
    },
    schema: 'Schema',
    schemaManual: 'Manually defined',
    schemaAutomatic: 'Automatically defined',
    responsiveWarning:
      'To import data, please switch to a device with a wider screen resolution (eg: a laptop or a desktop computer).',
    previewLimited: 'Preview limited to {limit} columns',
    previewLimitedDescription: 'Manually defining a schema is not possible for tables with more than {limit} columns',
    loadingTitle: 'Previewing the schema,',
    loadingSubtitle: 'this could take up to one minute...',
    loadingLocationTitle: 'Checking the file type,',
    loadingLocationSubtitle: 'this could take up a few seconds...',
    resumableErrorTitle: "We couldn't preview the schema",
    nonResumableErrorTitle: 'File not supported',
    errorDescription: 'Try again or let CARTO automatically define the schema.',
    tryWithAnotherFile: 'Try with another file'
  },
  onboarding: {
    dontShowAgain: 'Don’t show again'
  },
  attributesAccordion: {
    title: 'Variables',
    subtitle: `{total} {total, plural,
        one { variable}
        other { variables}
    } from {table}`,
    variable: 'Variable',
    description: 'Description',
    aggMethod: 'Aggregation method'
  },
  deprecateDialog: {
    title: 'This method of {type} will only be available until {date}',
    description1: 'From now on, we recommend {type} using <a>CARTO Workflows</a> ',
    description2: 'or using the {type} module in the <a>CARTO Analytics Toolbox</a> directly in your data warehouse.',
    description3: 'You can read more about these changes in <a>our documentation</a>.',
    data: 'data',
    createTileset: 'creating tilesets',
    tiler: 'tiler'
  },
  viewMode: {
    tree: 'Tree view',
    list: 'List view'
  }
}
